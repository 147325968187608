import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./components/routes/PrivateRoutes";
import { useAuthContext } from "./context/AuthContext";

//Pages
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import CalendarView from "./pages/CalendarView";
import NewEvent from "./pages/NewEvent";
import ListPersonalEvent from "./pages/ListPersonalEvent";
import ListEvent from "./pages/ListEvent";
import NotAssignedEvent from "./pages/NotAssignedEvent";

import Absences from "./pages/Absences";
import Techs from "./pages/Techs";

const ROLES = {
  ADMIN: "ADMIN",
  USER: "USER",
  MANAGER: "MANAGER"
}


function App() {

  const { isAuth } = useAuthContext()

  return (

    <Routes>

      <Route path="/login" element={<Login />} />

      <Route path='/' element={
        <PrivateRoute isAuthenticated={isAuth} roles={[ROLES.ADMIN, ROLES.USER, ROLES.MANAGER]} >
          <Dashboard />
        </PrivateRoute>
      }>

        <Route path='/newEvent' element={
          <PrivateRoute isAuthenticated={isAuth} roles={[ROLES.ADMIN, ROLES.MANAGER, ROLES.USER]}>
            <NewEvent />
          </PrivateRoute>
        } />

        <Route path="/" element={<CalendarView />} />
        <Route path="/notEventAssigned" element={<NotAssignedEvent />} />
        <Route path="/listPersonalEvent" element={<ListPersonalEvent />} />
        <Route path="/absences" element={<Absences />} />
        <Route path="/techs" element={<Techs />} />
        <Route path="/local/event" element={
          <PrivateRoute isAuthenticated={isAuth} roles={[ROLES.ADMIN]}>
            <ListEvent />
          </PrivateRoute>} />
      </Route>

    </Routes>
  );
}

export default App;
