import { Container, Flex, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { getAllEventsByLocalId } from '../../service/database'
import LocalSelect from '../ui/input/LocalSelect'

import { useDataContext } from '../../context/DataContext'
import TableListEvent from '../ui/table/TableListEvent'
import MonthSelect from '../ui/input/MonthSelect'
import { getDateWithLastDayOfMonth } from '../../utils/date'



function ListEventLocal() {
    const { locales, techs } = useDataContext()

    //const [locales, setLocasl] = useState([])
    const [selectedLocal, setSelectedLocal] = useState()
    const [selectedMonthAndYear, setSelectedMonthAndYear] = useState()
    const [eventsByMonth, setEventsByMonth] = useState()

    const getEventByLocal = async () => {
        console.log(selectedLocal)

        const startDate = new Date(selectedMonthAndYear)
        const endDate = getDateWithLastDayOfMonth(selectedMonthAndYear)

        console.log(startDate)
        console.log(endDate)

        const res = await getAllEventsByLocalId(selectedLocal, startDate, endDate)
        console.log(res)

        res.total = 0

        res.forEach((event) => {
            event.backgroundColor = techs.find(tech => tech.id === event.uid)?.backgroundColor ? techs.find(tech => tech.id === event.uid).backgroundColor : "red"
            event.color = techs.find(tech => tech.id === event.uid)?.color ? techs.find(tech => tech.id === event.uid).color : "black"
            res.total += event.workedHours ? event.workedHours : 0
        })

        setEventsByMonth(res)
        console.log(res)
        console.log(selectedMonthAndYear)
    }


    useEffect(() => {
        if (selectedMonthAndYear) {
            getEventByLocal()
        }
    }, [selectedLocal, selectedMonthAndYear])


    return (
        <>
            <Container>
                <Flex direction={"column"} gap={4}>
                    <LocalSelect locales={locales} selectedLocal={selectedLocal} setSelectedLocal={setSelectedLocal} />
                    {
                        !selectedLocal ? <></> :
                            <MonthSelect selectedMonthAndYear={selectedMonthAndYear} setSelectedMonthAndYear={setSelectedMonthAndYear} />
                    }
                </Flex>
            </Container>

            {
                !selectedMonthAndYear ? <></> :
                    <div className='mt-8'>
                        {
                            !eventsByMonth || eventsByMonth.length === 0 ?
                                <div>
                                    <Container className='text-center mt-10'>
                                        <Text fontSize='2xl'>Nessun evento</Text>
                                    </Container>
                                </div>
                                :
                                <TableListEvent events={eventsByMonth} page={'eventLocal'} />
                        }
                    </div>
            }
        </>
    )
}

export default ListEventLocal