import { CalendarIcon, HamburgerIcon, NotAllowedIcon, PlusSquareIcon, Icon } from '@chakra-ui/icons'
import { Badge } from '@chakra-ui/react'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { useAuthContext } from '../../context/AuthContext'
import { BsFillPersonLinesFill } from 'react-icons/bs'

const routes = [
    {
        name: "Calendario",
        url: "",
        role: ["USER", "MANAGER", "ADMIN"],
        icon: <CalendarIcon />,
        badge: "MANAGER"
    },
    {
        name: "Eventi NON assegnati",
        url: "notEventAssigned",
        role: ["USER", "MANAGER", "ADMIN"],
        icon: <CalendarIcon />,
        badge: "MANAGER"
    },
    {
        name: "Aggiunta evento",
        url: "newEvent",
        role: ["USER", "MANAGER", "ADMIN"],
        icon: <PlusSquareIcon />,
        badge: "MANAGER"
    },
    {
        name: "Lista eventi personali",
        url: "listPersonalEvent",
        role: ["USER", "MANAGER", "ADMIN"],
        icon: <HamburgerIcon />,
        badge: "PERSONAL"
    },
    {
        name: "Assenze",
        url: "absences",
        role: ["USER", "MANAGER", "ADMIN"],
        icon: <NotAllowedIcon />,
        badge: "PERSONAL"
    },
    {
        name: "Tecnici",
        url: "techs",
        role: ["USER", "MANAGER", "ADMIN"],
        icon: <Icon as={BsFillPersonLinesFill} />,
        badge: "MANAGER"
    },
    {
        name: "Lista eventi totali",
        url: "/local/event",
        role: ["ADMIN"],
        icon: <HamburgerIcon />,
        badge: "ADMIN"
    }
]

function MenuLinks() {

    const { user } = useAuthContext()
    let acritveStyle = {
        backgroundColor: "rgb(241 245 249)"
    }


    return (
        <div className='flex flex-col space-y-4  items-start  glass h-full w-full rounded-3xl p-4'>
            {
                routes.map((route) => {
                    if (route.role.includes(user.role)) {
                        return (
                            <NavLink key={route.url}
                                to={route.url}
                                style={({ isActive }) =>
                                    isActive ? acritveStyle : undefined
                                }
                                className={"group rounded-lg hover:underline p-2 hover:bg-slate-100 w-full"}
                            >

                                <div className="flex-col items-center space-x-2">
                                    <span className='mr-4 rounded-2xl p-1 '>{route.icon}</span>{route.name} <span><Badge>{route.badge}</Badge></span>
                                </div>
                            </NavLink>
                        )
                    }

                })
            }

        </div>
    )
}

export default MenuLinks