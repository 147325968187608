import React from 'react'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../components/ui/header/Header'
import Sidebar from '../components/ui/sidebar/Sidebar'
import { requestForToken, onMessageListener } from '../lib/firebase'


function Dashboard() {

  useEffect(() => {
    requestForToken()
  }, [])

  onMessageListener()
    .then((payload) => {
      console.log(payload)
    })
    .catch((err) => console.log('failed: ', err));


  return (
    <div className='grid grid-cols-10 p-4 gap-2'>
      <div className='md:col-span-2 h-full'>
        <Sidebar />
      </div>
      <div className='md:col-span-8 col-span-10'>
        <Header />
        <Outlet />
      </div>
    </div>
  )
}

export default Dashboard