import { Container, Flex, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { getAllEventsByUid } from '../../service/database'
import TechSelect from '../ui/input/TechSelect'
import { useDataContext } from '../../context/DataContext'
import TableListEvent from '../ui/table/TableListEvent'
import MonthSelect from '../ui/input/MonthSelect'
import { getDateWithLastDayOfMonth } from '../../utils/date'



function ListEventTech() {
    const { techs } = useDataContext()

    console.log(techs)

    //const [locales, setLocasl] = useState([])
    const [selectedTech, setSelectedTech] = useState()
    const [selectedMonthAndYear, setSelectedMonthAndYear] = useState()
    const [eventsByMonth, setEventsByMonth] = useState()

    const getEventByTech = async () => {
        console.log(selectedTech)

        const startDate = new Date(selectedMonthAndYear)
        const endDate = getDateWithLastDayOfMonth(selectedMonthAndYear)

        console.log(startDate)
        console.log(endDate)

        const res = await getAllEventsByUid(selectedTech, startDate, endDate)
        console.log(res)

        res.total = 0

        res.forEach((event) => {
            event.backgroundColor = techs.find(tech => tech.id === event.uid)?.backgroundColor ? techs.find(tech => tech.id === event.uid).backgroundColor : "red"
            event.color = techs.find(tech => tech.id === event.uid)?.color ? techs.find(tech => tech.id === event.uid).color : "black"
            res.total += event.workedHours ? event.workedHours : 0
        })

        setEventsByMonth(res)
        console.log(res)
        console.log(selectedMonthAndYear)
    }


    useEffect(() => {
        if (selectedMonthAndYear) {
            getEventByTech()
        }
    }, [selectedTech, selectedMonthAndYear])


    return (
        <>
            <Container>
                <Flex direction={"column"} gap={4}>
                    <TechSelect techs={techs} selectedTech={selectedTech} setSelectedTech={setSelectedTech} />

                    {
                        !selectedTech ? <></> :
                            <MonthSelect selectedMonthAndYear={selectedMonthAndYear} setSelectedMonthAndYear={setSelectedMonthAndYear} />
                    }
                </Flex>
            </Container>

            {
                !selectedMonthAndYear ? <></> :
                    <div className='mt-8'>
                        {
                            !eventsByMonth || eventsByMonth.length === 0 ?
                                <div>
                                    <Container className='text-center mt-10'>
                                        <Text fontSize='2xl'>Nessun evento</Text>
                                    </Container>
                                </div>
                                :
                                <TableListEvent events={eventsByMonth} page={'eventTech'} />
                        }
                    </div>
            }
        </>
    )
}

export default ListEventTech