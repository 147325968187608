import React from 'react'
import { useAuthContext } from '../../../context/AuthContext'
import DarkModeButton from '../button/DarkModeButton'
import { Avatar, Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { logout } from '../../../service/auth'
import { ArrowLeftIcon } from '@chakra-ui/icons'
import MenuMobile from '../../menu/MenuMobile'


function Header() {
    const { user } = useAuthContext()
    console.log(user)
    const navigate = useNavigate()
    return (
        <div className='flex justify-between items-center glass p-6 rounded-xl'>
            <h2 className='md:block hidden'>Dashboard</h2>
            <MenuMobile />
            <div className='flex space-x-2 items-center'>
                
                <DarkModeButton />
                <Menu>
                    <MenuButton as={Button} leftIcon={<Avatar className='h-6' name={user.name} src={user.imgUrl} />}>
                        {user.name}
                    </MenuButton>
                    <MenuList>
                        <MenuItem icon={<ArrowLeftIcon className='h-4' />} className='text-red-600' onClick={() => logout()}>Logout</MenuItem>

                    </MenuList>
                </Menu>


            </div>
        </div>
    )
}

export default Header