import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import "react-big-calendar/lib/css/react-big-calendar.css"

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

//Router
import { BrowserRouter } from "react-router-dom";

// Chakra
import { ChakraProvider } from '@chakra-ui/react'
import AuthContextProvider from './context/AuthContext';
import DataContextProvider from './context/DataContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <ChakraProvider>
    <BrowserRouter>
      <AuthContextProvider>
        <DataContextProvider>
          <App />
        </DataContextProvider>
      </AuthContextProvider>
    </BrowserRouter>
  </ChakraProvider>

);

serviceWorkerRegistration.register();