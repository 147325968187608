import React, { useMemo, useState, useEffect } from 'react'
import { Badge, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Radio, RadioGroup, useColorMode, useDisclosure, VStack, FormControl, useToast, IconButton, Text, Avatar, Input, Container, Flex } from '@chakra-ui/react'
import {
  Calendar,
  momentLocalizer,
} from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/it'

import { getAllEvents, getNotAvaiableTech, updateEventAssignamnet, deleteEventById, getAllAbsences } from '../service/database'
import { CalendarIcon, CloseIcon, DeleteIcon, EditIcon, InfoIcon, Icon } from '@chakra-ui/icons'
import { FaBuilding } from 'react-icons/fa';
import { useDataContext } from '../context/DataContext'
import { Loading } from 'notiflix/build/notiflix-loading-aio';
import LocalSelect from '../components/ui/input/LocalSelect'
import { getDateWithFirstDayOfPreviousMonth, getDateWithLastDayOfNextMonth } from '../utils/date'

function CalendarView() {

  // Context
  const { locales, techs, techsAvaiable } = useDataContext()

  // State
  const [eventsList, setEventsList] = useState([])
  const [eventsListToRender, setEventsListToRender] = useState([])

  const [selectedEvent, setSelectedEvent] = useState()
  const [selectedTech, setSelectedTech] = useState([])
  const [sceltoSelect, setSceltoSelect] = useState('')
  const [loadingAssignEvent, setloadingAssignEvent] = useState(false)
  const [editMod, setEditMod] = useState(true)
  const [noteInfo, setNoteInfo] = useState('')
  const [selectedLocal, setSelectedLocal] = useState()

  const [startDate, setStartDate] = useState(getDateWithFirstDayOfPreviousMonth(new Date()))
  const [endDate, setEndDate] = useState(getDateWithLastDayOfNextMonth(new Date()))

  const toast = useToast()


  // Modal
  const { isOpen, onOpen, onClose } = useDisclosure()

  moment.locale("it")
  const localizer = momentLocalizer(moment)

  const getEvents = async () => {
    Loading.pulse()
    
    const events = await getAllEvents(startDate, endDate)

    events.forEach((event) => {
      event.backgroundColor = event.isAssigned === false ? "red" : techs.find(tech => tech.id === event.uid)?.backgroundColor
      event.color = event.isAssigned === false ? "black" : techs.find(tech => tech.id === event.uid)?.color
    })

    const absences = await getAllAbsences()

    console.log("assenze", absences)
    console.log("eventi", events)
    setEventsList(events.concat(absences))
    setEventsListToRender(events.concat(absences));
    Loading.remove()
  }

  // const getEventsCalendarByLocalId = async () => {
  //   const events = await getAllEvents(selectedLocal)

  //   events.forEach((event) => {
  //     event.backgroundColor = event.isAssigned === false ? "red" : techs.find(tech => tech.id === event.uid)?.backgroundColor
  //     event.color = event.isAssigned === false ? "black" : techs.find(tech => tech.id === event.uid)?.color
  //   })

  //   const absences = await getAllAbsences()

  //   console.log("assenze", absences)
  //   console.log("eventi", events)
  //   setEventsList(events.concat(absences))
  //   setEventsListToRender(events.concat(absences))
  // }

  const { components, views } = useMemo(
    () => ({
      components: {
        dateCellWrapper: ColoredDateCellWrapper,
        timeSlotWrapper: ColoredDateCellWrapper
      },
      views: ["month", "week", "day", "agenda"],
    }),
    []
  )

  const onSelectEvent = async (event) => {
    Loading.pulse()
    setSelectedEvent(event)

    console.log(event)
    setNoteInfo(event?.noteInfo)

    if (event.type !== "ABSENCE") {
      setSceltoSelect(event.uid)

      // scaricare utenti disponibili
      const res = await getNotAvaiableTech(event.start)
      console.log(res)

      let techsToSelect = []

      techsAvaiable.forEach(tech => {
        console.log(tech)

        let obj = {}
        let presentTech = res.find(t => t.uid === tech.id)
        console.log("presentTech", presentTech)

        if (presentTech) {
          obj.nameTech = tech.name
          obj.surnameTech = tech.surname
          obj.idTech = tech.id
          obj.imgUrl = tech.imgUrl
          obj.isDisabled = true
          obj.type = presentTech.type === "EVENT" ? presentTech.shortLocalName : "ASSENTE"

          techsToSelect.push(obj)
        } else {
          obj.nameTech = tech.name
          obj.surnameTech = tech.surname
          obj.imgUrl = tech.imgUrl
          obj.idTech = tech.id
          obj.isDisabled = false

          techsToSelect.push(obj)
        }
      })

      setSelectedTech(techsToSelect)

      console.log(techsToSelect)
    }

    Loading.remove()
    onOpen()
  }

  const updateEventAssign = async () => {
    setloadingAssignEvent(true)
    let nameTech = techs.find(tech => tech.id === sceltoSelect)?.name
    let surnameTech = techs.find(tech => tech.id === sceltoSelect)?.surname

    const res = await updateEventAssignamnet(selectedEvent.id, sceltoSelect, nameTech, surnameTech, noteInfo)

    if (res) {
      toast({
        title: 'Modifica evento avvenuta!',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    } else {
      toast({
        title: 'Modifica evento NON avvenuta!',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }

    getEvents()
    setloadingAssignEvent(false)
    setEditMod(true)
    onClose()
  }

  const editEvent = () => {
    setEditMod(!editMod)
  }

  const closeModal = () => {
    setEditMod(true)
    onClose()
  }

  const deleteEvent = async () => {
    if (selectedEvent.workedHours) {
      toast({
        title: 'Impossibile eliminare un evento con ore lavorate!',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    } else {
      await deleteEventById(selectedEvent.id)

      toast({
        title: 'Evento eliminato!',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })

      getEvents()
      setEditMod(true)
      onClose()
    }
  }

  const workedHoursCompiledYetToast = () => {
    toast({
      title: "Ore già compilate!",
      description: "Non è possibile eliminare un evento con ore già compilate",
      status: "error",
      duration: 1000,
      isClosable: true,
    })
  }


  useEffect(() => {
    if (techs) {
      getEvents()
    }
  }, [techs])

  useEffect(() => {

    getEvents()

  }, [startDate, endDate])

  useEffect(() => {
    if (selectedLocal) {
      const eventFilter = [...eventsList]
      const newArray = eventFilter.filter(event => event.localId === selectedLocal || event.type === "ABSENCE");
      setEventsListToRender(newArray);
      // getEventsCalendarByLocalId()
    } else {
      setEventsListToRender(eventsList);
    }
  }, [selectedLocal, eventsList])

  return (
    <>
      <div className='mt-8'>
        <Container className='mt-4 mb-4'>
          <Flex direction={"column"} gap={4}>
            <LocalSelect locales={locales} selectedLocal={selectedLocal} setSelectedLocal={setSelectedLocal} />
          </Flex>
        </Container>

        <Calendar
          events={eventsListToRender}
          localizer={localizer}
          startAccessor="start"
          endAccessor="end"
          components={components}
          onSelectEvent={(event) => {
            onSelectEvent(event)
          }}
          onNavigate={(dateChangedOnNavigate => {
            console.log("dateChangedOnNavigate", dateChangedOnNavigate)
            setStartDate(getDateWithFirstDayOfPreviousMonth(dateChangedOnNavigate))
            setEndDate(getDateWithLastDayOfNextMonth(dateChangedOnNavigate))
          }
          )}
          step={60}
          views={views}
          eventPropGetter={(myEventsList) => {
            const backgroundColor = myEventsList.backgroundColor ? myEventsList.backgroundColor : 'blue';
            const color = myEventsList.color ? myEventsList.color : 'black';
            return { style: { backgroundColor, color } }
          }}
        />
      </div>

      {/* MODAL */}
      <Modal isOpen={isOpen} onClose={closeModal}>
        <ModalOverlay

          backdropFilter='blur(10px) hue-rotate(90deg)'
        />
        <ModalContent>
          <ModalHeader className='flex items-center'>{selectedEvent?.title}
            {
              selectedEvent?.type === "ABSENCE" ? <></> :
                <div className='ml-6'>
                  <IconButton icon={editMod ? <EditIcon /> : <CloseIcon />} onClick={editEvent}> Edit</IconButton>
                </div>
            }
          </ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            <div>
              {
                selectedEvent?.type === "ABSENCE" ? <></> :
                  <div className='flex'>
                    <Icon as={FaBuilding} h={6} /><Text><span className='font-bold ml-2'>Locale: </span> {selectedEvent?.shortLocalName}</Text>
                  </div>
              }

              <div className='flex'>
                <CalendarIcon h={6} /><Text><span className='font-bold ml-2'>Giorno: </span> {selectedEvent?.start.toLocaleDateString('it-IT', { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' })}</Text>
              </div>

              <div className='flex items-center items'>
                <InfoIcon h={6} /><Text><span className='font-bold ml-2'>Info: </span></Text>
                <Input className='ml-2'
                  onChange={(e) => setNoteInfo(e.target.value)}
                  placeholder="Note"
                  size="sm"
                  type="text"
                  value={noteInfo}
                  isReadOnly={selectedEvent?.type === "ABSENCE"}
                />
              </div>
            </div>

            {
              selectedEvent?.type === "ABSENCE" ? <></> :
                <div className='mt-3'>
                  {/* TODO: fare in modo che sia required*/}
                  <FormControl isRequired>
                    <RadioGroup onChange={val => setSceltoSelect(val)} value={sceltoSelect} >
                      <VStack spacing={1} direction={['column']} align='stretch' >
                        {
                          selectedTech.map((tech) => {
                            return (
                              <Radio key={tech.idTech} isDisabled={tech.isDisabled && editMod} value={tech.idTech}>
                                <div className='flex items-center rounded-lg'>
                                  <Avatar size='sm' className='mr-2' name={tech.nameTech} src={tech.imgUrl} /> {tech.nameTech} {tech.surnameTech} <Badge className='ml-2'>{tech.type}</Badge>
                                </div>
                              </Radio>
                            )
                          })
                        }
                      </VStack>
                    </RadioGroup>
                  </FormControl>
                </div>
            }
          </ModalBody>
          <ModalFooter>
            {
              selectedEvent?.type === "ABSENCE" ? <></> :
                <div className='flex'>
                  {
                    editMod ? <></>
                      :
                      <div onMouseEnter={() => selectedEvent.workedHoursCompiled ? workedHoursCompiledYetToast() : null} >
                        <IconButton isDisabled={selectedEvent.workedHoursCompiled} className='mr-6' icon={<DeleteIcon />} onClick={() => deleteEvent()} backgroundColor={'red'}> Edit</IconButton>
                      </div>
                  }
                  <Button isLoading={loadingAssignEvent} onClick={() => updateEventAssign()} colorScheme='blue' mr={3} >
                    Conferma
                  </Button>
                </div>
            }
            <Button onClick={closeModal} variant='ghost'>Chiudi</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}


const ColoredDateCellWrapper = ({ children }) => {
  const { colorMode } = useColorMode();

  return React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: colorMode === "dark" ? 'rgba(255,255,255,0.2)' : "white"
    },
  })
}
export default CalendarView