import React, { useState } from 'react'

import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Text,
    IconButton,
    Center,
    Input,
    InputGroup,
    InputRightAddon
} from '@chakra-ui/react'

import { EditIcon } from '@chakra-ui/icons'
import { useEffect } from 'react'

function TableListEvent({ events, page, insertTime, workedHoursCompiledYetToast, dateAfterTodayToast }) {
    const [payForHour, setPayForHour] = useState(0)
    const [earnings, setEarnings] = useState(0)

    const viewTotalRow = {
        "notAssigned": false,
        "eventTech": true,
        "eventLocal": true,
        "personalEvent": true,
    }

    const dateAfterToday = (event) => {
        if (new Date(event.dateEvent) > new Date()) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        if (events || payForHour) {
            let moltiplication = events.total * payForHour
            setEarnings(moltiplication.toFixed(2))
        }
    }, [payForHour, events])

    return (
        <>
            <div className=''>
                <TableContainer>
                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                <Th>Data</Th>
                                <Th>Tecnico</Th>
                                <Th>Locale</Th>
                                <Th>Note</Th>
                                <Th>Inizio - Fine</Th>
                                <Th>Ore lavorate</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                events.map(event => {
                                    return (

                                        <Tr key={event.id}>
                                            <Td>{event.dateEvent.toLocaleDateString()}</Td>

                                            {
                                                !event.isAssigned ? <><Td></Td></> :
                                                    <Td><span className='uppercase px-2 py-1 rounded-lg bg-opacity-60 border' style={{ backgroundColor: event?.backgroundColor, color: event.color }}>{event.techName}</span></Td>

                                            }
                                            <Td><Text as={'b'}>{event.shortLocalName}</Text></Td>
                                            <Td>{event?.noteInfo}</Td>
                                            <Td>{event?.workedHoursCompiled ? event?.dayAndHourStart.toLocaleString('it') : '?'} - {event?.workedHoursCompiled ? event?.dayAndHourEnd.toLocaleString('it') : '?'}</Td>
                                            <Td>{event?.workedHoursCompiled ? event?.workedHours : '?'}</Td>

                                            {page === 'personalEvent' ?
                                                event?.requestedHour ?
                                                    <Td><Center onMouseEnter={() => event.workedHoursCompiled ? workedHoursCompiledYetToast() : dateAfterTodayToast(event)} > <IconButton isDisabled={event.workedHoursCompiled || dateAfterToday(event)} icon={<EditIcon />} onClick={() => insertTime(event)} /></Center></Td> :
                                                    <></>
                                                : <></>
                                            }

                                            {/* <Td><Center><IconButton backgroundColor={'red'} icon={<DeleteIcon />} onClick={() => deleteEvent(event.id)} /> </Center>   </Td> */}
                                        </Tr>
                                    )
                                })
                            }

                            {
                                !viewTotalRow[page] ? <></> :
                                    <>
                                        <Tr key={"total"}>
                                            <Td />
                                            <Td />
                                            <Td />
                                            <Td />
                                            <Td className='text-right'><Text as={'b'}>Totale ore lavorate:</Text></Td>
                                            <Td>{events.total} <Text as={'b'}>h</Text></Td>
                                        </Tr>
                                        <Tr key={"pay"}>
                                            <Td />
                                            <Td />
                                            <Td />
                                            <Td />
                                            <Td className='text-right'><Text as={'b'}>Compenso orario:</Text></Td>
                                            <Td>
                                                <InputGroup>
                                                    {/* <InputLeftElement
                                                        pointerEvents='none'
                                                        color='gray.400'
                                                        fontSize='1.2em'
                                                        children='€/h'
                                                    /> */}
                                                    <Input
                                                        value={payForHour}
                                                        onChange={(e) => setPayForHour(e.target.value)}
                                                        size="md"
                                                        type="number"
                                                        htmlSize={4} width='60px'
                                                    />
                                                    <InputRightAddon children='€/h' />
                                                </InputGroup></Td>
                                        </Tr>
                                        <Tr key={"earnings"}>
                                            <Td />
                                            <Td />
                                            <Td />
                                            <Td />
                                            <Td className='text-right'><Text as={'b'}>Totale:</Text></Td>
                                            <Td>{earnings} <Text as={'b'}>€</Text></Td>
                                        </Tr>
                                    </>
                            }
                        </Tbody>
                    </Table>
                </TableContainer>
            </div>
        </>
    )
}

export default TableListEvent