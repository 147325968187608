
import { auth } from "../lib/firebase";

export const login = async (provider) => {

    if (provider) {
        try {
            const res = await loginWithProvider(provider)
            return res

        } catch (e) {
            return e
        }

    } else {
        try {
            const res = await loginWithEmailAndPassword()
            return res
        } catch (e) {
            return e
        }
    }
}

export const logout = async () => {

    try {
        let res = await auth.signOut() 
        return res

    } catch (e) {
        return new Error(e)
    }
}

export const loginWithProvider = async (provider) => {

    try {
        let res = await auth.signInWithPopup(provider)
        return res
    } catch (e) {
        return new Error(e)
    }


}

export const loginWithEmailAndPassword = async (email, password) => {
 
    try {
    let res = await auth.signInWithEmailAndPassword(email, password)
    console.log(res)
        return res

    } catch (e) {
        return new Error(e)

    }
}

export const registerWithEmailAndPassword = async (email, password) => {

    try {
        let res = await auth.createUserWithEmailAndPassword(email, password)
        return res
    } catch (e) {
        return new Error(e)
    }
    
}