
import { Button, Flex, FormControl, FormLabel, Input, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import { saveSingleEvent } from '../../service/database'
import LocalSelect from '../ui/input/LocalSelect'
import { useDataContext } from '../../context/DataContext'

function NewEventSingle() {
    const { locales } = useDataContext()

    //const [locales, setLocasl] = useState([])
    const [selectedLocal, setSelectedLocal] = useState()
    const [selectedDayAndHour, setSelectedDayAndHour] = useState()
    const [noteInfo, setNoteInfo] = useState()
    const [numeroTech, setNumeroTech] = useState(1)



    const [loadingAddEvent, setLoadingAddEvent] = useState(false)

    const toast = useToast()

    const handleAddEvent = async (e) => {
        e.preventDefault();
        setLoadingAddEvent(true)

        let localNameIndex = locales.findIndex((loc) => loc.id === selectedLocal);
        let shortNameLocal = locales[localNameIndex].shortName
        let requestedHour = locales[localNameIndex].requestedHour

        console.log(noteInfo, selectedDayAndHour, selectedLocal, requestedHour)


        for (let index = 0; index < numeroTech; index++) {
            const addEventResponse = await saveSingleEvent(noteInfo, selectedDayAndHour, selectedLocal, shortNameLocal, requestedHour)
            console.log(addEventResponse.id)
            toast({
                title: 'Evento singolo aggiunto!',
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        }

        setLoadingAddEvent(false)
    }


    return (
        <form onSubmit={handleAddEvent} className="mt-4">

            <Flex direction={"column"} gap={4}>
                <LocalSelect locales={locales} selectedLocal={selectedLocal} setSelectedLocal={setSelectedLocal} />

                <FormControl isRequired>
                    <FormLabel htmlFor="date">Seleziona data</FormLabel>
                    <Input
                        onChange={(e) => setSelectedDayAndHour(e.target.value)}
                        placeholder="Seleziona giorno e ora"
                        size="md"
                        type="date"
                    />
                </FormControl>

                <FormControl isRequired>
                    <FormLabel htmlFor="n-tecnici">Numero tecnici</FormLabel>
                    <Input
                        onChange={(e) => setNumeroTech(e.target.value)}
                        placeholder="Numero tecnici"
                        size="md"
                        type="number"
                        min={1}
                    />
                </FormControl>

                {/* <FormControl isRequired>
                    <FormLabel htmlFor="n-tecnici">Richiesta ore lavorate</FormLabel>
                    <Switch id='isRequired' colorScheme='blue' isChecked={richiestaOre} onChange={(e) => {setRichiestaOre(!richiestaOre)}} isRequired />
                </FormControl> */}

                <FormControl>
                    <FormLabel htmlFor="date">Note</FormLabel>
                    <Input
                        onChange={(e) => setNoteInfo(e.target.value)}
                        placeholder="Note"
                        size="md"
                        type="text"
                    />
                </FormControl>

                <Button isLoading={loadingAddEvent} type="submit" colorScheme="blue">Aggiunta</Button>
            </Flex>
        </form>
    )
}

export default NewEventSingle