import { Heading, Image } from '@chakra-ui/react'
import React from 'react'
import logo from '../../../assets/img/disco.png'
import MenuLinks from '../../menu/MenuLinks'


function Sidebar() {
    return (
        <div className='lg:flex space-y-6 flex-col p-6 justify-center items-center hidden w-full rounded-lg sticky top-0'>
            <div className='flex items-center'>
                <Image src={logo} alt='logo' className='w-16 h-16' />
                <Heading as='h3' size='lg'>Disco Slot</Heading>
            </div>
            <MenuLinks />
            <div className='flex flex-col items-center'>
                <div>Versione 3.4.1</div>
                <div>by <a href='https://albertorizzi.dev'>Alberto Rizzi</a></div>
            </div>
        </div>
    )
}

export default Sidebar