import Moment from 'moment';
import { extendMoment } from 'moment-range';
import 'moment/locale/it';

export const getAllDayInARange = async (daySelected, startDay, endDay) => {
    // Moment
    const moment = extendMoment(Moment)
    const range = moment.range(startDay, endDay);

    let week = []
    for (let day of range.by('day', { excludeEnd: false })) {

        console.log(day)
        day.locale("it")

        var dayName = day.format('dddd').substring(0, 3)


        if (daySelected.includes(dayName)) {
            week.push(day.format('YYYY-MM-DD'))
        }
    }

    return week;
}

export const getMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    let res = date.toLocaleString('it-IT', { month: 'long' })
    return res
}

export const getDateWithLastDayOfMonth = (date) => {
    let dateParsed = new Date(date)
    return new Date(dateParsed.getFullYear(), dateParsed.getMonth() + 1, 0, 23, 59, 59);
}

export const getDateWithLastDayOfNextMonth = (date) => {
    let dateParsed = new Date(date);
    dateParsed.setMonth(dateParsed.getMonth() + 2); // Add 2 to go to the next month
    return new Date(dateParsed.getFullYear(), dateParsed.getMonth(), 0, 23, 59, 59);
}

export const getDateWithFirstDayOfPreviousMonth = (date) => {
    let dateParsed = new Date(date)
    const firstDayOfMonth = new Date(dateParsed.getFullYear(), dateParsed.getMonth() - 1, 1);
    return firstDayOfMonth
}