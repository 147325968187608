import React from 'react'
import LoginForm from '../components/ui/form/LoginForm'

function Login() {

    return (
        <>
            <div className='grid grid-cols-1 md:grid-cols-2 w-full min-h-screen'>
                <div className=' hidden md:block bg-login bg-cover'></div>
                <div className=''><LoginForm /></div>
            </div>
        </>

    )
}

export default Login