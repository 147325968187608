import { HamburgerIcon } from '@chakra-ui/icons'
import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Heading, Image, useDisclosure } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import MenuLinks from './MenuLinks'
import logo from '../../assets/img/disco.png'

import { useLocation } from 'react-router-dom'

function MenuMobile() {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const location = useLocation();


  useEffect(() => {
    onClose()
  }, [location])


  return (
    <div className='block lg:hidden'>
      <Button onClick={onOpen}>
        <HamburgerIcon className='h-4' />
      </Button>
      <Drawer placement={"left"} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth='1px'>
            <div className='flex items-center justify-center'>
              <Image src={logo} alt='logo' className='w-16 h-16' />
              <Heading as='h3' size='lg'>Disco Slot</Heading>
            </div>
          </DrawerHeader>
          <DrawerBody>
            <MenuLinks />
          </DrawerBody>
          <div className='flex flex-col items-center m-3'>
            <div>Versione 3.4.1</div>
            <div>by <a href='https://albertorizzi.dev'>Alberto Rizzi</a></div>
          </div>
        </DrawerContent>
      </Drawer>
    </div>
  )
}

export default MenuMobile