import React from 'react'

import {
  Container,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel
} from '@chakra-ui/react'

import ListEventLocal from '../components/section/ListEventLocal'
import ListEventTech from '../components/section/ListEventTech'

function ListPersonalEvent() {
  return (
    <Tabs align='center ' isFitted variant='soft-rounded'>
      <Container className='mt-8'>
        <TabList>
          <Tab>Eventi per locale</Tab>
          <Tab>Eventi per tecnico</Tab>
        </TabList>
      </Container>
      <TabPanels>
        <TabPanel>
          <ListEventLocal />
        </TabPanel>
        <TabPanel>
          < ListEventTech />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export default ListPersonalEvent