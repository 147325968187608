import React, { useEffect, useState } from 'react'
import { useAuthContext } from '../context/AuthContext'
import { FaBuilding } from 'react-icons/fa';

import moment from 'moment';

import { getAllEventsByUid, updateEventHourWorked } from '../service/database'

import {
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  useDisclosure,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Icon,
  Flex,
  Container
} from '@chakra-ui/react'

import { CalendarIcon } from '@chakra-ui/icons'

import TableListEvent from '../components/ui/table/TableListEvent'
import MonthSelect from '../components/ui/input/MonthSelect';
import { Loading } from 'notiflix/build/notiflix-loading-aio';
import { getDateWithLastDayOfMonth } from '../utils/date';

function ListPersonalEvent() {

  // Context
  const { uid, user } = useAuthContext()


  // State
  const [selectedEvent, setSelectedEvent] = useState()
  const [selectedDayAndHourStart, setSelectedDayAndHourStart] = useState()
  const [selectedDayAndHourEnd, setSelectedDayAndHourEnd] = useState()
  const [durationHour, setDurationHour] = useState(0)
  const [selectedMonthAndYear, setSelectedMonthAndYear] = useState()
  const [eventsByMonth, setEventsByMonth] = useState()

  // Modal
  const { isOpen, onOpen, onClose } = useDisclosure()

  const toast = useToast()

  const getEventsOfUser = async () => {
    Loading.pulse()

    console.log(selectedMonthAndYear)
    const startDate = new Date(selectedMonthAndYear)
    const endDate = getDateWithLastDayOfMonth(selectedMonthAndYear)

    console.log(startDate)
    console.log(endDate)

    const res = await getAllEventsByUid(uid, startDate, endDate)
    console.log(res)

    res.total = 0

    res.forEach((event) => {
      event.backgroundColor = user.backgroundColor
      event.color = user.color
      res.total += event.workedHours ? event.workedHours : 0
    })

    setEventsByMonth(res)
    Loading.remove()
  }


  const insertTime = async (event) => {
    setSelectedEvent(event)
    onOpen()
  }


  const calculateDiffDate = () => {
    var a = moment(selectedDayAndHourStart);
    var b = moment(selectedDayAndHourEnd);
    let diff = b.diff(a, 'hour', true)

    diff = Math.round(diff * 100) / 100

    console.log(diff)
    setDurationHour(diff)
    console.log(diff * 17)
  }

  const addEventHourWorked = async () => {
    const res = updateEventHourWorked(selectedEvent.id, selectedDayAndHourStart, selectedDayAndHourEnd, durationHour)
    console.log(res)

    toast({
      title: "Modifica effettuata",
      description: "L'evento è stato modificato",
      status: "success",
      duration: 9000,
      isClosable: true,
    })

    getEventsOfUser()
    onClose()
  }

  const workedHoursCompiledYetToast = () => {
    toast({
      title: "Ore già compilate",
      description: "Hai già compilato le ore di lavoro per questa serata",
      status: "error",
      duration: 1000,
      isClosable: true,
    })
  }

  const dateAfterTodayToast = (event) => {
    if (new Date(event.dateEvent) > new Date()) {
      toast({
        title: "Ore NON compilabili",
        description: "La data dell'evento non può essere successiva alla data odierna",
        status: "error",
        duration: 1000,
        isClosable: true,
      })
    }
  }

  useEffect(() => {
    if (selectedMonthAndYear) {
      getEventsOfUser()
    }
  }, [selectedMonthAndYear])

  useEffect(() => {
    if (selectedDayAndHourStart && selectedDayAndHourEnd) {
      if (selectedDayAndHourStart > selectedDayAndHourEnd) {
        toast({
          title: 'La data di inizio deve essere minore della data di fine!',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      } else {
        calculateDiffDate()
      }
    }
  }, [selectedDayAndHourStart, selectedDayAndHourEnd])


  return (
    <>
      <Heading className='text-center py-8' as={"h2"}>I miei eventi</Heading>
      <Container>
        <MonthSelect selectedMonthAndYear={selectedMonthAndYear} setSelectedMonthAndYear={setSelectedMonthAndYear} />
      </Container>

      {
        !selectedMonthAndYear ? <></> :
          <div>
            {
              !eventsByMonth || eventsByMonth.length === 0 ?
                <div>
                  <Container className='text-center mt-10'>
                    <Text fontSize='2xl'>Nessun evento NON ASSEGNATO</Text>
                  </Container>
                </div> :
                <div>
                  <div className='mt-8'>
                    <TableListEvent events={eventsByMonth} page={'personalEvent'}
                      insertTime={insertTime}
                      workedHoursCompiledYetToast={workedHoursCompiledYetToast}
                      dateAfterTodayToast={dateAfterTodayToast} />
                  </div>
                </div>
            }
          </div>

      }

      {/* MODAL */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay backdropFilter='blur(10px) hue-rotate(90deg)' />
        <ModalContent>
          <ModalHeader>Riepilogo</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Flex direction={"column"} gap={4}>
              <div>
                <div className='flex'>
                  <Icon as={FaBuilding} h={6} /><Text><span className='font-bold ml-2'>Locale: </span> {selectedEvent?.shortLocalName}</Text>
                </div>
                <div className='flex'>
                  <CalendarIcon h={6} /><Text><span className='font-bold ml-2'>Giorni: </span> {selectedEvent?.dateEvent.toLocaleDateString('it-IT', { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' })}</Text>
                </div>
              </div>

              <FormControl isRequired>
                <FormLabel htmlFor="date">Seleziona data e ora di inzio</FormLabel>
                <Input
                  value={selectedDayAndHourStart}
                  onChange={(e) => setSelectedDayAndHourStart(e.target.value)}
                  placeholder="Seleziona giorno e ora di inizio"
                  size="md"
                  type="datetime-local"
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel htmlFor="date">Seleziona data e ora di fine</FormLabel>
                <Input
                  onChange={(e) => setSelectedDayAndHourEnd(e.target.value)}
                  value={selectedDayAndHourEnd}
                  placeholder="Seleziona giorno e ora di fine"
                  size="md"
                  type="datetime-local"
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel htmlFor="date">Durata ore</FormLabel>
                <Input value={durationHour}
                  placeholder="Durata ore"
                  size="md"
                  type="number"
                  readOnly={true}
                />
              </FormControl>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={() => addEventHourWorked()}>
              Conferma
            </Button>
            <Button onClick={onClose} variant='ghost'>Chiudi</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ListPersonalEvent