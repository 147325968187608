import React, { useEffect, useState } from 'react'
import { getAllEventsNotAssigned } from '../service/database'
import { Loading } from 'notiflix/build/notiflix-loading-aio';

import {
  Heading,
  Text,
  Container
} from '@chakra-ui/react'

import TableListEvent from '../components/ui/table/TableListEvent'
import MonthSelect from '../components/ui/input/MonthSelect';
import { getDateWithLastDayOfMonth } from '../utils/date';

function NotAssignedEvent() {
  // State
  const [selectedMonthAndYear, setSelectedMonthAndYear] = useState()
  const [eventsByMonth, setEventsByMonth] = useState()

  const getEvents = async () => {
    Loading.pulse()

    console.log(selectedMonthAndYear)
    const startDate = new Date(selectedMonthAndYear)
    const endDate = getDateWithLastDayOfMonth(selectedMonthAndYear)

    console.log(startDate)
    console.log(endDate)

    const res = await getAllEventsNotAssigned(startDate, endDate)
    console.log(res)

    setEventsByMonth(res)
    Loading.remove()
    console.log(res)
    console.log(selectedMonthAndYear)
  }

  useEffect(() => {
    if (selectedMonthAndYear) {
      getEvents()
    }
  }, [selectedMonthAndYear])

  return (
    <>
      <Heading className='text-center py-8' as={"h2"}>Eventi non assegnati</Heading>
      <Container>
        <MonthSelect selectedMonthAndYear={selectedMonthAndYear} setSelectedMonthAndYear={setSelectedMonthAndYear} />
      </Container>

      {
        !selectedMonthAndYear ? <></> :
          <div>
            {
              !eventsByMonth || eventsByMonth.length === 0 ?
                <div>
                  <Container className='text-center mt-10'>
                    <Text fontSize='2xl'>Nessun evento NON ASSEGNATO</Text>
                  </Container>
                </div> :
                <div>
                  <div className='mt-8'>
                    <TableListEvent events={eventsByMonth} page={'notAssigned'} />
                  </div>
                </div>
            }
          </div>

      }
    </>
  )
}

export default NotAssignedEvent