import { db } from '../lib/firebase'
import { getDoc, doc, collection, getDocs, Timestamp, addDoc, query, where, deleteDoc, updateDoc, orderBy } from 'firebase/firestore'

export const getUserById = async (id) => {
    const res = await getDoc(doc(db, "user", id))
    if (res.exists()) {
        console.log(res.data())
        return res.data()
    } else {
        return
    }
}

export const getAllLocal = async () => {
    const res = await getDocs(collection(db, "local"));

    let locals = []

    res.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots

        let obj = doc.data()
        obj.id = doc.id
        locals.push(obj)
    });

    return locals
}

export const getAllTechnician = async () => {
    const res = await getDocs(collection(db, "user"));

    let users = []

    res.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots

        let obj = doc.data()
        obj.id = doc.id
        users.push(obj)
    })
    return users

}

export const getAllTechnicianAvaiable = async () => {
    const res = await getDocs(query(collection(db, "user"), where("avaiable", "==", true)));

    let users = []

    res.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots

        let obj = doc.data()
        obj.id = doc.id
        users.push(obj)
    })
    return users
}

export const saveSingleEvent = async (noteInfo, selectedDay, localId, shortLocalName, requestedHour) => {
    const docData = {
        noteInfo: noteInfo ? noteInfo : null,
        shortLocalName: shortLocalName,
        localId: localId,
        dateEvent: Timestamp.fromDate(new Date(selectedDay)),
        isAssigned: false,
        requestedHour: requestedHour
    }

    const docRef = await addDoc(collection(db, "event"), docData);
    return docRef
}

export const saveSingleAbsence = async (noteInfo, selectedDayStart, selectedDayEnd, uid, techName, techSurname) => {
    const docData = {
        noteInfo: noteInfo ? noteInfo : null,
        start: Timestamp.fromDate(new Date(selectedDayStart)),
        end: Timestamp.fromDate(new Date(selectedDayEnd)),
        uid: uid,
        techName: techName,
        techSurname: techSurname
    }

    // Add a new document with a generated id.
    const docRef = await addDoc(collection(db, "absence"), docData);
    return docRef
}

export const getAllEvents = async (startDate, endDate) => {
    const eventsRef = collection(db, "event");

    console.log("startDate db", startDate)
    console.log("endDate db", endDate)

    let q = null

    q = query(eventsRef,
        where("dateEvent", "<=", Timestamp.fromDate(new Date(endDate))),
        where("dateEvent", ">=", Timestamp.fromDate(new Date(startDate))))

    const res = await getDocs(q);

    let events = []

    res.forEach((doc) => {
        let obj = doc.data()
        obj.id = doc.id
        obj.start = obj.dateEvent.toDate()
        obj.end = obj.dateEvent.toDate()
        obj.title = obj.techName ? obj.shortLocalName + " - " + obj.techName.toUpperCase() + (obj.surnameTech?.toUpperCase() ? " " + obj.surnameTech?.toUpperCase() : "") : obj.shortLocalName + " - ?"
        obj.allDay = true
        obj.type = "EVENT"
        events.push(obj)
    });

    return events
}

export const getAllEventsByUid = async (uid, startDate, endDate) => {
    const eventsRef = collection(db, "event");

    // Create a query against the collection.
    const q = query(eventsRef, where("uid", "==", uid),
        where("dateEvent", "<=", Timestamp.fromDate(new Date(endDate))),
        where("dateEvent", ">=", Timestamp.fromDate(new Date(startDate))),
        orderBy("dateEvent", "asc"));

    const res = await getDocs(q);

    let events = []

    res.forEach((doc) => {
        let obj = doc.data()
        obj.id = doc.id
        obj.dateEvent = obj.dateEvent.toDate()
        obj.dayAndHourStart = obj?.dayAndHourStart?.toDate()
        obj.dayAndHourEnd = obj?.dayAndHourEnd?.toDate()
        events.push(obj)
    });

    return events
}

export const getAllEventsByLocalId = async (localId, startDate, endDate) => {
    const eventsRef = collection(db, "event");

    // Create a query against the collection.
    const q = query(eventsRef, where("localId", "==", localId),
        where("dateEvent", "<=", Timestamp.fromDate(new Date(endDate))),
        where("dateEvent", ">=", Timestamp.fromDate(new Date(startDate))),
        orderBy("dateEvent"));

    const res = await getDocs(q);

    let events = []

    res.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots

        let obj = doc.data()
        obj.id = doc.id
        obj.dateEvent = obj.dateEvent.toDate()
        obj.dayAndHourStart = obj?.dayAndHourStart?.toDate()
        obj.dayAndHourEnd = obj?.dayAndHourEnd?.toDate()
        events.push(obj)
    });

    return events
}

export const getAllEventsNotAssigned = async (startDate, endDate) => {
    const eventsRef = collection(db, "event");

    // Create a query against the collection.
    const q = query(eventsRef, where("isAssigned", "==", false),
        where("dateEvent", "<=", Timestamp.fromDate(new Date(endDate))),
        where("dateEvent", ">=", Timestamp.fromDate(new Date(startDate))),
        orderBy("dateEvent"));

    const res = await getDocs(q);

    let events = []

    res.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots

        let obj = doc.data()
        obj.id = doc.id
        obj.dateEvent = obj.dateEvent.toDate()
        obj.dayAndHourStart = obj?.dayAndHourStart?.toDate()
        obj.dayAndHourEnd = obj?.dayAndHourEnd?.toDate()
        events.push(obj)
    });

    return events
}

export const getAllAbsencesByUid = async (uid) => {
    const absencesRef = collection(db, "absence");

    // Create a query against the collection.
    const q = query(absencesRef, where("uid", "==", uid), orderBy("start", "desc"));

    const res = await getDocs(q);

    let absences = []

    res.forEach((doc) => {
        let obj = doc.data()
        obj.id = doc.id
        obj.start = obj.start.toDate()
        obj.end = obj.end.toDate()
        absences.push(obj)
    });
    console.log(absences)
    return absences
}

export const getAllAbsences = async () => {
    const res = await getDocs(collection(db, "absence"));

    let absences = []

    res.forEach((doc) => {
        let obj = doc.data()
        obj.id = doc.id
        obj.start = obj.start.toDate()
        obj.end = obj.end.toDate()
        obj.allDay = true
        obj.backgroundColor = "orange"
        obj.color = "black"
        obj.title = "ASSENZA " + obj.techName + (obj.techSurname ? " " + obj.techSurname : "")
        obj.type = "ABSENCE"
        absences.push(obj)
    });
    return absences
}


export const deleteAbsenceById = async (id) => {
    deleteDoc(doc(db, "absence", id)).then(res => {
        return true
    }).catch(error => {
        return false
    })
}

export const getNotAvaiableTech = async (selectedDay) => {
    const eventsRef = collection(db, "event");

    const q = query(eventsRef, where("isAssigned", "==", true),
        where("dateEvent", "==", Timestamp.fromDate(new Date(selectedDay)))
    );

    const res = await getDocs(q);

    let notAvaiability = []

    res.forEach((doc) => {
        let obj = doc.data()
        let newObj = {}
        newObj.id = doc.id
        newObj.uid = obj.uid
        newObj.type = "EVENT"
        newObj.shortLocalName = obj.shortLocalName

        notAvaiability.push(newObj)
    });


    const absencesRef = collection(db, "absence");

    const queryAbsence = query(absencesRef,
        where("start", "<=", Timestamp.fromDate(new Date(selectedDay)))
    );

    const res_absences = await getDocs(queryAbsence);

    res_absences.forEach((doc) => {
        let obj = doc.data()

        if (obj.end.toDate() >= selectedDay) {
            let newObj = {}
            newObj.id = doc.id
            newObj.uid = obj.uid
            newObj.type = "ABSENCE"
            notAvaiability.push(newObj)
        }
    })
    console.log(notAvaiability)
    return notAvaiability
}

export const updateEventAssignamnet = async (id, uid, techName, surnameTech, noteInfo) => {
    const docRef = doc(db, "event", id)

    let event = {}

    if (uid && techName) {
        event = {
            uid: uid,
            isAssigned: true,
            techName: techName,
            surnameTech: surnameTech,
            noteInfo: noteInfo === "" ? null : noteInfo
        }
    } else {
        event = {
            noteInfo: noteInfo === "" ? null : noteInfo
        }
    }

    try {
        await updateDoc(docRef, event);
        return true
    } catch (error) {
        return false
    }
}

export const updateEventHourWorked = async (id, dayAndHourStart, dayAndHourEnd, workedHours) => {
    const docRef = doc(db, "event", id);

    try {
        await updateDoc(docRef,
            {
                dayAndHourStart: Timestamp.fromDate(new Date(dayAndHourStart)),
                dayAndHourEnd: Timestamp.fromDate(new Date(dayAndHourEnd)),
                workedHours: workedHours,
                workedHoursCompiled: true
            });

        return true
    } catch (error) {
        return false
    }
}

export const deleteEventById = async (id) => {
    deleteDoc(doc(db, "event", id)).then(res => {
        return true
    }).catch(error => {
        return false
    })
}