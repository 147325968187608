import { FormControl, FormLabel, Input } from '@chakra-ui/react'
import React from 'react'

function MonthSelect({ selectedMonthAndYear, setSelectedMonthAndYear }) {
    return (
        <FormControl isRequired >
            <FormLabel htmlFor="locale">Seleziona mese e anno</FormLabel>
            <Input
                onChange={(e) => setSelectedMonthAndYear(e.target.value)}
                placeholder="Seleziona mese e anno"
                size="md"
                type="month"
                value={selectedMonthAndYear}
            />
        </FormControl>
    )
}

export default MonthSelect