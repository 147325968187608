import React from 'react'

import { Tabs, TabList, TabPanels, Tab, TabPanel, Container } from '@chakra-ui/react'
import NewAbsenceSingle from '../components/section/NewAbsenceSingle'
import ListAbsence from '../components/section/ListAbsence'


function Absences() {
    return (
        <Container className='mt-8'>
            <Tabs align='center' isFitted variant='soft-rounded'>
                <TabList>
                    <Tab>Inserisci assenza</Tab>
                    <Tab>Lista assenze</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <NewAbsenceSingle />
                    </TabPanel>
                    <TabPanel>
                        <ListAbsence/>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Container>
    )
}

export default Absences