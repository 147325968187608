import { Avatar, Heading, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, Container } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useDataContext } from '../context/DataContext'




function Techs() {

  const { techsAvaiable } = useDataContext()






  useEffect(() => {
    console.log(techsAvaiable)
  }, [])

  return (
    <div>
      <Heading className='text-center py-8' as={"h2"}>Tecnici</Heading>
      <Container maxW='4xl'>
        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th></Th>
                <Th>Nome</Th>
                <Th>Cognome</Th>
                <Th>Colore</Th>
                <Th>Provenienza</Th>
              </Tr>
            </Thead>
            <Tbody>
              {
                techsAvaiable.map(tech => {
                  return (
                    <Tr key={tech.id}>
                      <Td><Avatar className='sm' name={tech.name + tech.surname} src={tech.imgUrl} /></Td>
                      <Td><Text as={'b'}>{tech.name}</Text></Td>
                      <Td><Text as={'b'}>{tech.surname}</Text></Td>
                      <Td><span className='uppercase px-2 py-1 rounded-lg bg-opacity-60 border' style={{backgroundColor: tech.backgroundColor, color: tech.color}}>{tech.backgroundColor}</span></Td>
                      <Td>{tech.city}</Td>
                    </Tr>

                  )
                })
              }
            </Tbody>
          </Table>
        </TableContainer>
      </Container>
    </div>
  )
}

export default Techs