import { useEffect } from 'react';
import {
    useNavigate
} from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContext';
import Loading from '../section/Loading';



const PrivateRoute = ({ isAuthenticated, children, roles }) => {

    //context
    const { user, loading } = useAuthContext()

    const navigate = useNavigate();

    useEffect(() => {
        if (!loading) {
            if (user == undefined || user == null) {
                navigate("/login")
            }else if(!roles.includes(user.role)){
                navigate("/")
            }

            
        }
    })

    if (!user || loading) {
        return <Loading />
    }

    return children
}

export default PrivateRoute