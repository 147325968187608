import { Button, Flex, FormControl, FormLabel, Input, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useAuthContext } from '../../context/AuthContext'
import { saveSingleAbsence } from '../../service/database'

function NewAbsenceSingle() {
    const { uid, user } = useAuthContext()
    const [selectedDayStart, setSelectedDayStart] = useState("")
    const [selectedDayEnd, setSelectedDayEnd] = useState("")
    const [noteInfo, setNoteInfo] = useState("")

    const toast = useToast()

    const [loadingAddEvent, setLoadingAddEvent] = useState(false)

    const handleAddEvent = async (e) => {
        e.preventDefault();
        setLoadingAddEvent(true)



        if (selectedDayStart > selectedDayEnd) {
            toast({
                title: 'Data di inizio maggiore della data di fine!',
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
            setLoadingAddEvent(false)
            return
        }

        const addEventResponse = await saveSingleAbsence(noteInfo, selectedDayStart, selectedDayEnd, uid, user.name, user.surname)
        setLoadingAddEvent(false)

        if (addEventResponse.id) {
            toast({
                title: 'Assenza inserita!',
                status: 'success',
                duration: 9000,
                isClosable: true,
            })

            setSelectedDayStart("")
            setSelectedDayEnd("")
            setNoteInfo("")
        } else {
            toast({
                title: 'Assenza NON inserita!',
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }

    return (
        <form onSubmit={handleAddEvent} className="mt-4">
            <Flex direction={"column"} gap={4}>
                <FormControl isRequired>
                    <FormLabel htmlFor="date">Seleziona data inizio</FormLabel>
                    <Input
                        value={selectedDayStart}
                        onChange={(e) => setSelectedDayStart(e.target.value)}
                        placeholder="Seleziona giorno e ora"
                        size="md"
                        type="date"
                    />
                </FormControl>

                <FormControl isRequired>
                    <FormLabel htmlFor="date">Seleziona data fine</FormLabel>
                    <Input
                        value={selectedDayEnd}
                        onChange={(e) => setSelectedDayEnd(e.target.value)}
                        placeholder="Seleziona giorno e ora"
                        size="md"
                        type="date"
                    />
                </FormControl>

                <FormControl>
                    <FormLabel htmlFor="date">Note</FormLabel>
                    <Input
                        value={noteInfo}
                        onChange={(e) => setNoteInfo(e.target.value)}
                        placeholder="Note"
                        size="md"
                        type="text"
                    />
                </FormControl>

                <Button isLoading={loadingAddEvent} type="submit" colorScheme="blue">Aggiunta assenza</Button>
            </Flex>
        </form>
    )
}

export default NewAbsenceSingle