import { FormControl, FormLabel, Select } from '@chakra-ui/react'
import React from 'react'

function TechSelect({ selectedTech, setSelectedTech, techs }) {
    return (
        <FormControl isRequired >
            <FormLabel htmlFor="locale">Seleziona tecnico</FormLabel>
            <Select value={selectedTech} onChange={(e) => setSelectedTech(e.target.value)} placeholder='Seleziona tecnico'>
                {
                    techs.map((tech, i) => {
                        return <option key={tech.id} value={tech.id}>{tech.name} {tech.surname}</option>
                    })
                }
            </Select>
        </FormControl>
    )
}

export default TechSelect