import { FormControl, FormLabel, Select } from '@chakra-ui/react'
import React from 'react'

function LocalSelect({ selectedLocal, setSelectedLocal, locales }) {
    return (
        <FormControl isRequired >
            <FormLabel htmlFor="locale">Seleziona locale</FormLabel>
            <Select value={selectedLocal} onChange={(e) => setSelectedLocal(e.target.value)} placeholder='Seleziona locale'>
                {
                    locales.map((loc, i) => {
                        return <option key={loc.id} value={loc.id}>{loc.name}</option>
                    })
                }
            </Select>
        </FormControl>
    )
}

export default LocalSelect