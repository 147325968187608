import { Flex, Heading } from '@chakra-ui/react'
import React from 'react'

function Loading() {
  return (
    <Flex alignItems={"center"} justifyContent={"center"} className='min-h-screen'>
        <Heading as={"h1"} className='animate-pulse font-bold text-3xl'>Loading</Heading>
    </Flex>
  )
}

export default Loading