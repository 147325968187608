import { Container } from '@chakra-ui/react'
import React from 'react'

import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import NewEventSingle from '../components/section/NewEventSingle'
import NewEventMultiple from '../components/section/NewEventMultiple'

function NewEvent() {
    return (
        <Container className='mt-8'>
            <Tabs align='center' isFitted variant='soft-rounded'>
                <TabList>
                    <Tab>Evento speciale (singolo)</Tab>
                    <Tab>Evento ricorrente</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <NewEventSingle />
                    </TabPanel>
                    <TabPanel>
                        <NewEventMultiple />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Container>
    )
}

export default NewEvent