import React, { useEffect, useState } from 'react'

import {
    Table,
    Thead,
    Tbody,

    Tr,
    Th,
    Td,

    TableContainer,
    Container,
    Heading,
    useToast,
    IconButton
} from '@chakra-ui/react'
import { useAuthContext } from '../../context/AuthContext'
import { getAllAbsencesByUid } from '../../service/database'
import { DeleteIcon } from '@chakra-ui/icons'

import { deleteAbsenceById } from '../../service/database'
function ListAbsence() {
    // Context
    const { uid } = useAuthContext()

    // State
    const [absences, setAbsences] = useState([])

    const toast = useToast()

    const getAbsencesOfUser = async () => {
        const res = await getAllAbsencesByUid(uid)
        setAbsences(res)
    }

    const deleteAbsence = async (id) => {
        console.log(id)

        try {
            await deleteAbsenceById(id)
            toast({
                title: 'Assenza eliminata!',
                status: 'success',
                duration: 9000,
                isClosable: true,
            })

            getAbsencesOfUser()
        } catch (error) {
            toast({
                title: 'Assenza NON eliminata!',
                status: 'error',
                duration: 9000,
                isClosable: true,
            })

            getAbsencesOfUser()
        }

    }


    useEffect(() => {
        getAbsencesOfUser()

    }, [])


    return (
        <div><Heading className='text-center py-8' as={"h2"}>Le mie assenze</Heading>

            <Container className=''>

                <TableContainer>
                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                <Th>Data</Th>
                                <Th>Nota</Th>
                                <Th>Elimina</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                absences.map(absence => {

                                    return (
                                        <Tr key={absence.id}>
                                            <Td>{new Date(absence.start).toLocaleDateString('it-IT')} - {new Date(absence.end).toLocaleDateString('it-IT')}</Td>
                                            <Td>{absence.noteInfo}</Td>
                                            <Td>
                                                <IconButton icon={<DeleteIcon />} backgroundColor={'red'}
                                                    onClick={() => deleteAbsence(absence.id)} />
                                            </Td>
                                        </Tr>
                                    )
                                })
                            }
                        </Tbody>
                    </Table>
                </TableContainer>
            </Container></div >
    )
}

export default ListAbsence