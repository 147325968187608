import { Button, Checkbox, CheckboxGroup, Flex, FormControl, FormLabel, Input, Text, useCheckboxGroup, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import LocalSelect from '../ui/input/LocalSelect'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import { getAllDayInARange } from '../../utils/date';

import { saveSingleEvent } from '../../service/database'

import { useAuthContext } from '../../context/AuthContext'
import { useDataContext } from '../../context/DataContext'

function NewEventMultiple() {
    const { uid } = useAuthContext()
    const { locales } = useDataContext()

    // State
    const [selectedLocal, setSelectedLocal] = useState('')
    const [selectedDayAndHourStart, setSelectedDayAndHourStart] = useState()
    const [selectedDayAndHourEnd, setSelectedDayAndHourEnd] = useState()
    const [noteInfo, setNoteInfo] = useState()
    const [loadingAddEvent, setLoadingAddEvent] = useState(false)
    const [selectedDayWeek, setSelectedDayWeek] = useState([])
    const [numeroTech, setNumeroTech] = useState(1)

    const toast = useToast()

    // Checbox
    const { value, getCheckboxProps } = useCheckboxGroup()


    // Modal
    const { isOpen, onOpen, onClose } = useDisclosure()


    const handleAddEvent = async (e) => {
        e.preventDefault();


        let week = await getAllDayInARange(value, selectedDayAndHourStart, selectedDayAndHourEnd)

        setSelectedDayWeek(week)

        // Open Modal
        onOpen()
    }

    const getNameLocal = (id) => {
        if (id === '') {
            return ''
        } else {
            let localNameIndex = locales.findIndex((loc) => loc.id === id);
            return locales[localNameIndex].shortName
        }
    }

    const addMultipleEvents = async () => {
        setLoadingAddEvent(true)

        let shortNameLocal = getNameLocal(selectedLocal)
        let requestedHour = locales[locales.findIndex((loc) => loc.id === selectedLocal)].requestedHour;

        selectedDayWeek.forEach(async (day) => {
            for (let index = 0; index < numeroTech; index++) {
                const addEventResponse = await saveSingleEvent(noteInfo, day, selectedLocal, shortNameLocal, requestedHour)
                console.log(addEventResponse.id)

                toast({
                    title: 'Evento periodico aggiunto!',
                    description: "Event ID: " + addEventResponse.id,
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
            }
        })


        onClose()

        setLoadingAddEvent(false)
    }


    return (
        <>
            <form onSubmit={handleAddEvent} className="mt-4">
                <Flex direction={"column"} gap={6}>
                    <LocalSelect locales={locales} selectedLocal={selectedLocal} setSelectedLocal={setSelectedLocal} />

                    <FormControl>
                        <FormLabel>Quali giorni</FormLabel>

                        <div className='grid md:grid-cols-4 grid-cols-3 gap-2'>
                            <CheckboxGroup>
                                <Checkbox  {...getCheckboxProps()} value={"lun"}>Lunedì</Checkbox>
                                <Checkbox  {...getCheckboxProps()} value={"mar"}>Martedì</Checkbox>
                                <Checkbox  {...getCheckboxProps()} value={"mer"}>Mercoledì</Checkbox>
                                <Checkbox   {...getCheckboxProps()} value={"gio"}>Giovedì</Checkbox>
                                <Checkbox  {...getCheckboxProps()} value={"ven"}>Venerdì</Checkbox>
                                <Checkbox  {...getCheckboxProps()} value={"sab"}>Sabato</Checkbox>
                                <Checkbox  {...getCheckboxProps()} value={"dom"}>Domenica</Checkbox>
                            </CheckboxGroup>
                        </div>
                    </FormControl>


                    <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
                        <FormControl isRequired>
                            <FormLabel htmlFor="date">Seleziona data di inzio</FormLabel>
                            <Input
                                onChange={(e) => setSelectedDayAndHourStart(e.target.value)}
                                placeholder="Seleziona giorno e ora di inizio"
                                size="md"
                                type="date"
                            />
                        </FormControl>

                        <FormControl isRequired>
                            <FormLabel htmlFor="date">Seleziona data di fine</FormLabel>
                            <Input
                                onChange={(e) => setSelectedDayAndHourEnd(e.target.value)}
                                placeholder="Seleziona giorno e ora di fine"
                                size="md"
                                type="date"
                            />
                        </FormControl>
                    </div>

                    <FormControl isRequired>
                        <FormLabel htmlFor="n-tecnici">Numero tecnici</FormLabel>
                        <Input
                            onChange={(e) => setNumeroTech(e.target.value)}
                            placeholder="Numero tecnici"
                            size="md"
                            type="number"
                            min={1}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel htmlFor="date">Note</FormLabel>
                        <Input
                            onChange={(e) => setNoteInfo(e.target.value)}
                            placeholder="Note"
                            size="md"
                            type="text"
                        />
                    </FormControl>

                    <Button type="submit" colorScheme="blue">Aggiunta</Button>
                </Flex>

            </form>



            {/* MODAL */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay

                    backdropFilter='blur(10px) hue-rotate(90deg)'
                />
                <ModalContent>
                    <ModalHeader>Riepilogo</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>

                        <Text><span className='font-bold'>Locale:</span> {getNameLocal(selectedLocal)}</Text>
                        <Text>
                            <span className='font-bold'>Giorni:</span>
                        </Text>
                        <ul>
                            {
                                selectedDayWeek.map((day) => {
                                    return <li key={day}>{new Date(day).toLocaleDateString('it')}</li>
                                })
                            }
                        </ul>
                    </ModalBody>

                    <ModalFooter>
                        <Button isLoading={loadingAddEvent} colorScheme='blue' mr={3} onClick={() => addMultipleEvents()}>
                            Conferma
                        </Button>
                        <Button onClick={onClose} variant='ghost'>Chiudi</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default NewEventMultiple