import React, { createContext, useState, useContext, useEffect } from 'react';
import { getAllLocal, getAllTechnician, getAllTechnicianAvaiable } from '../service/database';

// context init
export const DataContext = createContext();
export const useDataContext = () => useContext(DataContext);

// Provider
function DataContextProvider({ children }) {

    const [locales, setLocales] = useState([]);
    const [techs, setTechs] = useState([]);
    const [techsAvaiable, setTechsAvaiable] = useState([]);

    const [mobileDrawer, setMobileDrawer] = useState(false);


    const initData = async () => {
        const locals = await getAllLocal()
        setLocales(locals)

        const technicianAvaiable = await getAllTechnicianAvaiable()
        setTechsAvaiable(technicianAvaiable)

        const technician = await getAllTechnician()
        setTechs(technician)
    }

    useEffect(() => {
        initData()
    }, [])

    return (
        <DataContext.Provider value={{
            locales,
            techs,
            techsAvaiable,
            mobileDrawer,
            setMobileDrawer
        }}>
            {
                children
            }
        </DataContext.Provider>
    );
}

export default DataContextProvider;