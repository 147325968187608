import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/section/Loading';
import { auth } from '../lib/firebase';
import { getUserById } from '../service/database';


// context init
export const AuthContext = createContext();
export const useAuthContext = () => useContext(AuthContext);

// Provider
function AuthContextProvider({ children }) {

    // Router
    const navigate = useNavigate()

    const [user, setUser] = useState(null);
    const [uid, setUid] = useState(null);
    const [credential, setCredential] = useState(null);
    const [isAuth, setIsAuth] = useState(false);
    const [loading, setLoading] = useState(true);

    const initUser = async (uid) => {
        setUid(uid)

        console.log("UID: " + uid)
        const user = await getUserById(uid)

        setUser(user)
        setIsAuth(true)
        setLoading(false)

        navigate("/")
    }

    useEffect(() => {
        setLoading(true)
        auth.onAuthStateChanged((res) => {



            if (res) {
                // GET USER INFO WITH UID

                initUser(res._delegate.uid)
            } else {
                setUser()
                setIsAuth(false)
                setLoading(false)
            }
        });
    }, [])

    if (loading) {
        return <Loading />
    }

    return (
        <AuthContext.Provider value={{
            user,
            uid,
            isAuth,
            credential,
            loading,
            setUser,
            setCredential,
            setIsAuth,
            setLoading
        }}>
            {
                children
            }
        </AuthContext.Provider>
    );
}

export default AuthContextProvider;