import React, { useState } from 'react'
import { Heading, Stack, Text, Button, HStack, FormControl, Input, FormLabel, Divider, VisuallyHidden, ButtonGroup, Container, Center, Image } from '@chakra-ui/react'
import { GoogleIcon } from '../icon/ProviderIcon'
import { loginWithEmailAndPassword, loginWithProvider } from '../../../service/auth'
import { googleProvider } from '../../../lib/firebase'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../../context/AuthContext'
import logo from '../../../assets/img/disco.png'


function LoginForm() {

    // STate
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [loadingLogin, setLoadingLogin] = useState()


    // Contetx
    const { loading } = useAuthContext()

    // ROuter
    const navigate = useNavigate()

    const handleLoginEmailPassword = async (e) => {
        e.preventDefault();
        setLoadingLogin(true)

        try {
            const res = await loginWithEmailAndPassword(email, password)

            if (res) {

                setLoadingLogin(false)
                navigate("/")

            }
            setLoadingLogin(false)


        } catch (e) {
            console.log(e)
            setLoadingLogin(false)

        }
    }

    const handleLoginProvider = async (provider) => {
        try {
            const res = await loginWithProvider(provider)
            navigate("/")

        } catch (e) {
            console.log(e)
        }
    }


    return (
        <div className='flex flex-col items-center justify-center h-full'>
            <Container>
                <div className='p-6'>
                    <div className='mb-12 flex justify-center'>
                        <div className='flex items-center'>
                            <Image src={logo} alt='logo' className='w-40 h-40' />
                            <Heading as='h2' size='xl'>Disco Slot</Heading>
                        </div>
                    </div>
                    <Stack spacing={"5"}>
                        <Center>
                            <Heading as='h3' size='md'>Accedi al tuo account</Heading>
                        </Center>
                        {/* <Center>
                            <HStack className='text-center'>
                                <Text>Non hai un account? </Text> <Button variant="link" colorScheme="blue">
                                    Registrati
                                </Button>
                            </HStack>
                        </Center> */}

                        <form onSubmit={handleLoginEmailPassword} className='shadow-lg rounded-lg p-6'>
                            <Stack spacing="5">
                                <FormControl isRequired>
                                    <FormLabel htmlFor="email">Email</FormLabel>
                                    <Input onChange={(e) => setEmail(e.target.value)} id="email" type="email" placeholder='Email' />
                                </FormControl>

                                <FormControl isRequired>
                                    <FormLabel htmlFor="password">Password</FormLabel>
                                    <Input onChange={(e) => setPassword(e.target.value)} id="password" type="password" placeholder='Password' />
                                </FormControl>

                                <Button isLoading={loadingLogin} type="submit" colorScheme="blue">Login</Button>

                                {/* <HStack>
                                    <Divider />
                                    <Text fontSize="sm" whiteSpace="nowrap" color="muted">
                                        o continua con
                                    </Text>
                                    <Divider />
                                </HStack>
                                <ButtonGroup variant="outline" spacing="4" width="full">

                                    <Button onClick={() => handleLoginProvider(googleProvider)} key={"google"} width="full">
                                        <VisuallyHidden>Sign in with Google</VisuallyHidden>
                                        <GoogleIcon />
                                    </Button>
                                </ButtonGroup> */}
                            </Stack>
                        </form>
                    </Stack>
                </div>
            </Container>
        </div>
    )
}

export default LoginForm